<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">

        <div class="page-title">Задайте Ваш вопрос</div>
        <div class="hr mb-4"></div>

        <div class="alert alert-info mb-4">
            Задайте Ваш вопрос, специалист пришлет Вам ответ и, возможно, опубликует в справочнике.
        </div>

        <div class="row mt-4">
            <div class="col-md-6 col-lg-6">


                <ui-input-box :error="errors.message">
                    <ui-textarea v-model="message" placeholder="Ваш вопрос" rows="3" />
                </ui-input-box>


                <div v-if="error" class="login-alert p-3 text-center mt-3">
                    <div class="text-primary">Внимание!</div>
                    <span>{{ error }}</span>
                </div>

                <div v-if="success" class="success-alert p-3 text-center mt-3">
                    <div class="text-primary">Внимание!</div>
                    <span>{{ success }}</span>
                </div>

            </div>
        </div>

        <ui-button @click="send" class="btn-base mt-3 me-2" :loading="loadingSend"
            :disabled="loadingSend">Отправить</ui-button>



        <div class="d-none d-md-block d-lg-block d-xl-block d-xxl-block">

            <div class="row">
                <div class="col-md">
                    <div class="page-title" style="padding-left: 234.52px;">Памятка клиенту</div>
                </div>
                <div class="col-auto pt-2">
                    <a href="/Памятка%20клиента%20СТОУН.pdf" class="btn btn-ghost" target="_blank" download>Памятка клиента PDF</a>
                </div>
            </div>
            <div class="hr mb-4"></div>
        </div>




        <div class="d-md-none">
            <div class="page-title">Памятка клиенту</div>
            <div class="hr mb-4"></div>
            <div class="text-end">
                <a href="/Памятка%20клиента%20СТОУН.pdf" class="btn btn-ghost mb-3" target="_blank" download>Памятка клиента PDF</a>
            </div>
        </div>



        <div v-if="list.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список вопросов пуст" />
        </div>

        <div v-else class="row">


            <div v-for="item, index in list" ref="tab" class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item" :class="item.tab && 'active'">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                        <button @click="setTab(index)" class="accordion-button" :class="!item.tab && 'collapsed'">
                            {{ item.name }}
                        </button>
                    </h2>
                    <div class="accordion-collapse collapse" :class="item.tab && 'show'">
                        <div class="accordion-body py-0" style="background: #fff;">
                            <div v-for="itemHelp in item.help">
                                <h2 v-html="itemHelp.q" class="mt-3"></h2>
                                <div v-html="itemHelp.a"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>

    </div>
</template>

<script>
import axios from 'axios'
import { postApi } from '@/run'

export default {
    name: 'accounting.contacts',
    data() {
        return {
            loading: false,
            loadingSend: false,
            list: [],
            error: null,
            errors: [],
            success: null
        }
    },
    components: {},
    created() {
        document.title = "Памятка клиенту - STONE-XXI"

        this.start()
    },
    methods: {
        start() {
            this.loading = true

            axios.post('//dev.asket.ru/api/help').then(response => {

                this.list = response.data

                this.loading = false

            })
        },
        send() {
            this.loadingSend = true
            this.error = null
            this.success = null

            postApi('/question', {
                message: this.message
            }).then(response => {

                if (response.error) {
                    this.error = response.error
                    this.errors = response.errors ? response.errors : []
                }

                if (response.status) {
                    this.success = response.success
                    this.errors = []
                }

                this.loadingSend = false
            })
        },
        setTab(index) {
            this.list = this.list.map((item, i) => !item.tab && index === i ? { ...item, tab: true } : { ...item, tab: false })

            setTimeout(() => {
                const top = this.$refs.tab[index].offsetTop;
                window.scrollTo(0, top);
            }, 0)

        }
    }
}
</script>