<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>


    <div v-else class="page-inner">

        <div class="page-title">Запрос на выписку из книг покупок и продаж</div>

        <div class="hr"></div>

        <div v-if="contracts.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список договоров пуст" />
        </div>

        <div v-else>

            <div class="row mt-4">
                <div class="col-md-6 col-lg-6">
                    <ui-input-box-floating :error="errors.pref">
                        <select class="form-control form-select" @change="selectPref" v-model="pref"
                            :disabled="loadingSend">
                            <option v-for="item in lessor" :value="item.pref">{{ item.name }}</option>
                        </select>
                        <ui-input-label>Лизингодатель</ui-input-label>
                    </ui-input-box-floating>

                    <ui-input-box-floating class="mt-3" :error="errors.dateStart || errors.dateEnd">
                        <select class="form-control form-select" @change="selectQuarter" v-model="quarter"
                            :disabled="loadingSend">
                            <option v-for="item, index in quarterList" :value="index">{{ item.quarter }}</option>
                        </select>
                        <ui-input-label>Квартал</ui-input-label>
                    </ui-input-box-floating>

                    <div v-if="error" class="login-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>{{ error }}</span>
                    </div>

                    <div v-if="success" class="success-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>{{ success }}</span>
                    </div>

                    <div v-if="!chekTime" class="login-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>Ваш запрос принят и будет выполнен в течение примерно 20 минут.
                            Сообщение о выполнении будет выслано на Ваш email {{ this.getEmail }}</span>
                    </div>

                </div>
            </div>

            <ui-button @click="send" class="btn-base mt-3 me-2" :loading="loadingSend"
                :disabled="loadingSend || !chekTime">Отправить
                запрос</ui-button>



            <div>
                <h2 class="title">Список запросов</h2>
                <div size="2" class="hr"></div>
            </div>

            <div v-if="list.length !== 0">

                <div class="scroll table-responsive mt-4">
                    <table class="table text-nowrap mb-0">
                        <thead>
                            <tr>
                                <th>Дата запроса</th>
                                <th>Дата начала</th>
                                <th>Дата окончания</th>
                                <th>Статус</th>
                                <th>Скачать</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item, index in list">
                                <td>{{ dateMask(item['ДатаПолучения']) }}</td>
                                <td>{{ dateMask(item['ДатаНачПлан']) }}</td>
                                <td>{{ dateMask(item['ДатаКонПлан']) }}</td>
                                <td style="width: 100%;">{{ item.status }}</td>
                                <td class="text-end">

                                    <div v-if="item.status === 'Ошибка'">
                                        <ui-button :disabled="item.status === 'Ошибка'"
                                            class="btn-base btn-small">Ошибка</ui-button>
                                    </div>

                                    <div v-else>
                                        <ui-button @click="downloadR(index, 'kppr', item.recId)"
                                            :disabled="item.status === 'Не выполнен' || item.status === 'Ошибка' || item.loadingR"
                                            :progress="item.progressR ? item.progressR : 0" :loading="item.loadingR"
                                            class="btn-base btn-small">Выписка из книги покупок</ui-button>&nbsp;

                                        <ui-button @click="download(index, 'kppo', item.recId)"
                                            :disabled="item.status === 'Не выполнен' || item.status === 'Ошибка' || item.loading"
                                            :progress="item.progress ? item.progress : 0" :loading="item.loading"
                                            class="btn-base btn-small">Выписка из книги продаж</ui-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <Pagination class="mt-3" :page="page" :total="listTotal" :item="itemPage" :loading="loadingPage" />

            </div>

            <div v-else class="position-relative no-list-data">
                <ui-no-data-page text="Список запросов пуст" />
            </div>

        </div>
    </div>
</template>

<script>
import { getApi, postApi, dateMask, downloadApi } from '@/run'

import { mapGetters } from "vuex";

import Pagination from '@/component/pagination'

export default {
    name: 'request.accounting',
    data() {
        return {
            loading: false,
            loadingSend: false,
            loadingPage: false,
            pref: null,
            lessor: [],
            contracts: [],
            quarterList: [],
            quarter: null,
            list: [],
            listTotal: null,
            itemPage: 0,
            error: null,
            errors: [],
            success: null,
            dateStart: null,
            dateEnd: null,
            dateMask: dateMask,
            chekTime: true
        }
    },
    components: {
        Pagination
    },
    created() {
        document.title = "Запрос на выписку из книг покупок и продаж - STONE-XXI"
        this.start()
    },
    computed: {
        ...mapGetters({
            getEmail: 'main/getEmail'
        })
    },
    methods: {

        progress(btn, progress) {
            this.list = this.list.map((item, i) => i === btn ? { ...item, progress: progress } : { ...item })
        },
        download(btn, type, file) {
            this.list = this.list.map((item, i) => i === btn ? { ...item, loading: true } : { ...item })
            downloadApi('/download', {
                type: type,
                file: file
            }, btn, this.progress).then(response => {

                this.list = this.list.map((item, i) => i === btn ? { ...item, progress: false, loading: false } : { ...item })

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },

        progressR(btn, progress) {
            this.list = this.list.map((item, i) => i === btn ? { ...item, progressR: progress } : { ...item })
        },
        downloadR(btn, type, file) {
            this.list = this.list.map((item, i) => i === btn ? { ...item, loadingR: true } : { ...item })
            downloadApi('/download', {
                type: type,
                file: file
            }, btn, this.progressR).then(response => {

                this.list = this.list.map((item, i) => i === btn ? { ...item, progress: false, loadingR: false } : { ...item })

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },

        start() {

            this.loading = true

            getApi('/request/buysell', {}).then(response => {

                if (response.status) {
                    this.pref = response.pref
                    this.lessor = response.lessor
                    this.contracts = response.contracts
                    this.quarterList = response.quarterList

                    if (response.quarterList) {
                        this.quarter = 0
                        this.dateStart = response.quarterList[0].dateStart ? response.quarterList[0].dateStart : null
                        this.dateEnd = response.quarterList[0].dateEnd ? response.quarterList[0].dateEnd : null
                        this.list = response.list
                        this.listTotal = response.total
                        this.chekTime = response.chekTime
                    } else {
                        this.quarter = null
                    }
                }

                this.loading = false

            })

        },
        page(page) {
            this.loadingPage = true

            this.itemPage = page

            getApi('/request/buysell', {
                offset: page
            }).then(response => {

                if (response.status) {

                    this.listTotal = response.total
                    this.list = response.list

                }

                this.loadingPage = false

            })
        },
        send() {

            this.loadingSend = true
            this.error = null
            this.errors = []
            this.success = null

            postApi('/request/requestBuysell', {
                pref: this.pref,
                dateStart: this.dateStart,
                dateEnd: this.dateEnd
            }).then(response => {

                if (response.status) {
                    this.itemPage = 0
                    this.listTotal = response.total
                    this.list = response.list
                    this.success = response.success
                    this.chekTime = false
                }

                if (response.error) {
                    this.error = response.error
                    this.errors = response.errors ? response.errors : []
                }

                this.loadingSend = false

            })

        },
        selectPref() {
            this.error = null
            this.errors = []
            this.success = null
        },
        selectQuarter(event) {
            this.dateStart = this.quarterList[event.target.value].dateStart ? this.quarterList[event.target.value].dateStart : null
            this.dateEnd = this.quarterList[event.target.value].dateEnd ? this.quarterList[event.target.value].dateEnd : null
        }
    }
}
</script>