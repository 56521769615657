<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>


    <div v-else class="page-inner">

        <div class="page-title">Запросы на сверку по фотоштрафам</div>

        <div class="hr"></div>

        <div v-if="contracts.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список договоров пуст" />
        </div>

        <div v-else>

            <div class="row mt-4">
                <div class="col-md-6 col-lg-6">
                    <ui-input-box-floating :error="errors.pref">
                        <select class="form-control form-select" @change="selectPref" v-model="pref"
                            :disabled="loadingSend">
                            <option v-for="item in lessor" :value="item.pref">{{ item.name }}</option>
                        </select>
                        <ui-input-label>Лизингодатель</ui-input-label>
                    </ui-input-box-floating>

                    <div class="row">
                        <div class="col-6">
                            <div class="form-group mt-3">
                                <ui-input-box-floating class="datepicker-wrapper" :error="errors.dateStart">
                                    <ui-input v-model="dateStart" placeholder="Начало периода" autocomplete="off"
                                        :disabled="loadingSend" type="date" />
                                    <label>Начало периода</label>
                                </ui-input-box-floating>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mt-3">
                                <ui-input-box-floating class="datepicker-wrapper" :error="errors.dateEnd">
                                    <ui-input v-model="dateEnd" placeholder="Конец периода" autocomplete="off"
                                        :disabled="loadingSend" type="date" />
                                    <label>Конец периода</label>
                                </ui-input-box-floating>
                            </div>
                        </div>
                    </div>

                    <div v-if="error" class="login-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>{{ error }}</span>
                    </div>

                    <div v-if="success" class="success-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>{{ success }}</span>
                    </div>

                    <div v-if="!chekTime" class="login-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>Ваш запрос принят и будет выполнен в течение примерно 20 минут.
                            Сообщение о выполнении будет выслано на Ваш email {{ this.getEmail }}</span>
                    </div>

                </div>
            </div>

            <ui-button @click="send" class="btn-base mt-3 me-2" :loading="loadingSend" :disabled="loadingSend || !chekTime">Отправить
                запрос</ui-button>


            <div>
                <h2 class="title">Список запросов</h2>
                <div size="2" class="hr"></div>
            </div>

            <div v-if="list.length !== 0">

                <div class="scroll table-responsive mt-4">
                    <table class="table text-nowrap mb-0" style="vertical-align: top;">
                        <thead>
                            <tr>
                                <th>Дата запроса</th>
                                <th>Дата начала</th>
                                <th>Дата окончания</th>
                                <th>Статус</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item, index in list">
                                <td class="tdt">{{ dateMask(item['ДатаПолучения']) }}</td>
                                <td class="tdt">{{ dateMask(item['ДатаНачПлан']) }}</td>
                                <td class="tdt">{{ dateMask(item['ДатаКонПлан']) }}</td>
                                <td class="tdt">{{ item.status }}</td>
                                <td class="text-end">

                                    <ui-button @click="download(index, 'sv', item.recId)"
                                        :disabled="item.status === 'Не выполнен' || item.status === 'Ошибка' || item.loading"
                                        :progress="item.progress ? item.progress : 0" :loading="item.loading"
                                        class="btn-base btn-small">{{item.status === 'Ошибка' ? 'Ошибка' : 'Скачать'}}</ui-button>

                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <Pagination class="mt-3" :page="page" :total="listTotal" :item="itemPage" :loading="loadingPage" />

            </div>

            <div v-else class="position-relative no-list-data">
                <ui-no-data-page text="Список запросов пуст" />
            </div>

        </div>


    </div>
</template>

<script>
import { getApi, postApi, dateMask } from '@/run'
import moment from 'moment'

import { mapGetters } from "vuex"

import Pagination from '@/component/pagination'

export default {
    name: 'request.accounting',
    data() {
        return {
            loading: false,
            loadingSend: false,
            loadingPage: false,
            pref: null,
            lessor: [],
            list: [],
            listTotal: null,
            itemPage: 0,
            contracts: [],
            error: null,
            errors: [],
            success: null,
            dateStart: moment().format('YYYY-01-01'),
            dateEnd: moment().format('YYYY-MM-DD'),
            dateMask: dateMask,
            chekTime: true
        }
    },
    components: {
        Pagination
    },
    created() {
        document.title = "Запросы на сверку по фотоштрафам - STONE-XXI"

        this.start()
    },
    computed: {
        ...mapGetters({
            getEmail: 'main/getEmail'
        })
    },
    methods: {
        start() {

            this.loading = true

            getApi('/request/photofine', {}).then(response => {

                if (response.status) {
                    this.pref = response.pref
                    this.lessor = response.lessor
                    this.contracts = response.contracts
                    this.list = response.list
                    this.listTotal = response.total
                    this.chekTime = response.chekTime
                }

                this.loading = false

            })

        },
        page(page) {
            this.loadingPage = true

            this.itemPage = page

            getApi('/request/photofine', {
                offset: page
            }).then(response => {

                if (response.status) {

                    this.listTotal = response.total
                    this.list = response.list

                }

                this.loadingPage = false

            })
        },
        send() {

            this.loadingSend = true
            this.error = null
            this.errors = []
            this.success = null

            postApi('/request/requestPhotofine', {
                pref: this.pref,
                dateStart: this.dateStart,
                dateEnd: this.dateEnd
            }).then(response => {

                if (response.status) {
                    this.itemPage = 0
                    this.listTotal = response.total
                    this.list = response.list
                    this.success = response.success
                    this.chekTime = false
                }

                if (response.error) {
                    this.error = response.error
                    this.errors = response.errors ? response.errors : []
                }

                this.loadingSend = false

            })

        },
        selectPref() {
            this.error = null
            this.errors = []
            this.success = null
        }
    }
}
</script>