<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">

        <div class="page-title">Фотоштрафы</div>

        <div class="hr"></div>

        <div class="search-filter mt-4">
            <div class="search-filter__item">
                <ui-button :white="true" @click="setFilter(null)" class="search-filter__link" :class="!filter && 'active'"
                    :loading="loadingList && !filter" :disabled="loadingList">
                    Все <span v-if="(totalPaid + totalUnpaid) !== 0">({{ (totalPaid + totalUnpaid) }})</span>
                </ui-button>
            </div>
            <div class="search-filter__item">
                <ui-button :white="true" @click="setFilter('unpaid')" class="search-filter__link"
                    :class="filter === 'unpaid' && 'active'" :loading="loadingList && filter === 'unpaid'"
                    :disabled="loadingList">Неоплаченные <span v-if="totalUnpaid !== 0">({{
                        totalUnpaid }})</span></ui-button>
            </div>
            <div class="search-filter__item">
                <ui-button :white="true" @click="setFilter('paid')" class="search-filter__link"
                    :class="filter === 'paid' && 'active'" :loading="loadingList && filter === 'paid'"
                    :disabled="loadingList">Оплаченные
                    <span v-if="totalPaid !== 0">({{ totalPaid }})</span></ui-button>
            </div>
        </div>

        <div v-if="list.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список фотоштрафов пуст" />
        </div>

        <div v-else>

            <div v-if="totalPaid !== 0 && showPage !== 'unpaid'" class="alert alert-info mt-4 mb-0">
                Вы можете запросить акт сверки взаиморасчетов по штрафам в <RouterLink to="/request/photofine">разделе
                    «Запросы
                    на документы»</RouterLink>
            </div>

            <div v-if="totalUnpaid !== 0 && showPage !== 'paid'" class="row mt-4">
                <div class="col-md-6 col-lg-6">
                    <div class="login-alert p-3 text-center">
                        <div class="text-primary">Внимание!</div>
                        <span>Если Вы оплатили штраф самостоятельно, то пришлите нам подтверждение
                            оплаты штрафа. Для отправки нажмите на кнопку «Подтверждение оплаты»</span>
                    </div>
                </div>
            </div>

            <div class="scroll table-responsive mt-4">
                <!-- <table class="table text-nowrap mb-0"> -->
                <table class="table mb-0">
                    <thead>
                        <tr>
                            <th rowspan="2">ДЛ</th>
                            <th v-if="mvz" rowspan="2">МВЗ</th>
                            <th rowspan="2">Гос.Номер ТС</th>
                            <th colspan="3">Постановление</th>
                            <th rowspan="2" class="text-end">Сумма штрафа</th>
                            <th rowspan="2" class="text-end">Комиссия</th>
                            <th rowspan="2">Счет на оплату</th>
                            <th rowspan="2">Дата оплаты</th>
                        </tr>

                        <tr>
                            <th>Дата</th>
                            <th>Номер</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item, index in list"
                            :class="!item['ДатаОплаты'] && !item['Confirmations'] && 'table-danger'">
                            <td>{{ item['ДЛ'] }}</td>
                            <td v-if="mvz">{{ item['МВЗ'] }} {{ item['ФИО'] }}</td>
                            <td>{{ item['РегНомер'] }}</td>
                            <td>{{ dateMask(item['Дата']) }}</td>
                            <td>
                                <div>{{ item['НомерПостановления'] }}</div>
                                <div>{{ item['Примечания'] }}</div>
                            </td>
                            <td class="text-center">
                                <ui-button @click="download(index, 'fs', item['Код'])"
                                    :progress="item.progress ? item.progress : 0" :loading="item.loading"
                                    class="btn-base btn-small">Скачать</ui-button>
                            </td>
                            <td class="text-end">{{ moneyMask(item['Сумма']) }}</td>
                            <td class="text-end">{{ moneyMask(item['Комиссия']) }}</td>
                            <td class="text-center">
                                <ui-button @click="downloadInv(index, 'fsInv', item['Код'])"
                                    :progress="item.progressInv ? item.progressInv : 0" :loading="item.loadingInv"
                                    class="btn-base btn-small">Скачать</ui-button>
                            </td>
                            <td>
                                <span v-if="item['ДатаОплаты']">{{ dateMask(item['ДатаОплаты']) }}</span>

                                <a v-else class="btn btn-light btn-small"
                                    :href="'mailto:gudimenko@stone-xxi.ru; shineleva@stone-xxi.ru; sender4@stone-xxi.ru; sender5@stone-xxi.ru; sender6@stone-xxi.ru?subject=Подтверждение оплаты, постановление №' + item['НомерПостановления']">Подтверждение оплаты</a>
                                <!-- <ui-button v-else class="btn-light btn-small">Подтверждение оплаты</ui-button> -->
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <Pagination v-if="showPage === 'paid'" class="mt-3" :page="page" :item="itemPage" :total="totalPaid"
                :loading="loadingPage" />

            <Pagination v-else-if="showPage === 'unpaid'" class="mt-3" :page="page" :item="itemPage" :total="totalUnpaid"
                :loading="loadingPage" />

            <Pagination v-else class="mt-3" :page="page" :item="itemPage" :total="(totalPaid + totalUnpaid)"
                :loading="loadingPage" />

        </div>

    </div>

    <!-- <ui-modal v-if="modalConfirmation" :close="modalClose">
        <div class="modal-header">
            <h5 class="modal-title">Связаться с менеджером</h5>
            <button class="btn-close" type="button" @click="modalClose"></button>
        </div>
        <div class="modal-body">
            <p>Разберем ваш запрос в течение 3 рабочих дней.</p>

            <ui-input-box :error="errors.message">
                <ui-textarea v-model="message" placeholder="Сообщение" rows="3" />
            </ui-input-box>

            <div v-if="error" class="login-alert p-3 text-center mt-3">
                <div class="text-primary">Внимание!</div>
                <span>{{ error }}</span>
            </div>

            <div v-if="success" class="success-alert p-3 text-center mt-3">
                <div class="text-primary">Внимание!</div>
                <span>{{ success }}</span>
            </div>

            <ui-button @click="send" class="btn-base mt-3 me-2" :loading="loadingSend"
                :disabled="loadingSend">Отправить</ui-button>
        </div>
        <div class="modal-footer"></div>
    </ui-modal> -->

    <ui-modal v-if="modal.msg" :close="modalClose">
        <div class="modal-header">
            <h5 class="modal-title">{{ modal.title }}</h5>
            <button class="btn-close" type="button" @click="modalClose"></button>
        </div>
        <div class="modal-body">
            {{ modal.msg }}
        </div>
        <div class="modal-footer"></div>
    </ui-modal>
</template>

<script>
import { getApi, dateMask, moneyMask, downloadApi } from '@/run'

import Pagination from '@/component/pagination'

export default {
    name: 'accounting.photofine',
    data() {
        return {
            loading: false,
            loadingList: false,
            loadingPage: false,
            itemPage: 0,
            filter: 'unpaid',
            showPage: 'unpaid',
            list: [],
            totalPaid: null,
            totalUnpaid: null,
            mvz: false,
            dateMask: dateMask,
            moneyMask: moneyMask,
            modal: {},
        }
    },
    components: { Pagination },
    created() {
        document.title = "Фотоштрафы - STONE-XXI"

        this.start()
    },
    methods: {
        start() {

            this.loading = true

            getApi('/accounting/photofine', {
                filter: this.filter
            }).then(response => {

                if (response.status) {
                    this.list = response.list
                    this.totalPaid = response.totalPaid
                    this.totalUnpaid = response.totalUnpaid
                    this.mvz = response.mvz
                }

                this.loading = false

            })

        },
        setFilter(value) {

            this.loadingList = true

            this.filter = value

            getApi('/accounting/photofine', {
                filter: value
            }).then(response => {

                if (response.status) {
                    this.list = response.list
                    this.totalPaid = response.totalPaid
                    this.totalUnpaid = response.totalUnpaid
                    this.itemPage = 0
                    this.mvz = response.mvz
                }

                this.showPage = value

                this.loadingList = false

            })
        },
        page(page) {

            this.loadingPage = true
            this.itemPage = page

            getApi('/accounting/photofine', {
                filter: this.filter,
                offset: page
            }).then(response => {

                if (response.status) {
                    this.list = response.list
                    this.totalPaid = response.totalPaid
                    this.totalUnpaid = response.totalUnpaid
                    this.mvz = response.mvz
                }

                this.loadingPage = false

            })
        },
        progress(btn, progress) {
            this.list = this.list.map((item, i) => i === btn ? { ...item, progress: progress } : { ...item })
        },
        download(btn, type, file) {
            this.list = this.list.map((item, i) => i === btn ? { ...item, loading: true } : { ...item })
            downloadApi('/download', {
                type: type,
                file: file
            }, btn, this.progress).then(response => {

                this.list = this.list.map((item, i) => i === btn ? { ...item, progress: false, loading: false } : { ...item })

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },
        progressInv(btn, progress) {
            this.list = this.list.map((item, i) => i === btn ? { ...item, progressInv: progress } : { ...item })
        },
        downloadInv(btn, type, file) {
            this.list = this.list.map((item, i) => i === btn ? { ...item, loadingInv: true } : { ...item })
            downloadApi('/download', {
                type: type,
                file: file
            }, btn, this.progressInv).then(response => {

                this.list = this.list.map((item, i) => i === btn ? { ...item, progressInv: false, loadingInv: false } : { ...item })

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },
        modalClose() {
            this.modal = {}
        }
    }
}
</script>